import { createUseOpenFetch } from '#imports'
import type { paths as HycPaths, operations as HycOperations } from '#open-fetch-schemas/hyc'

export type OpenFetchClientName = 'hyc'

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useHyc = createUseOpenFetch<HycPaths>('hyc')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyHyc = createUseOpenFetch<HycPaths>('hyc', true)

export type HycResponse<T extends keyof HycOperations, R extends keyof HycOperations[T]['responses'] = 200 extends keyof HycOperations[T]['responses'] ? 200 : never> = HycOperations[T]['responses'][R] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type HycRequestBody<T extends keyof HycOperations> = HycOperations[T]['requestBody'] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type HycRequestQuery<T extends keyof HycOperations> = HycOperations[T]['parameters'] extends { query?: infer U } ? U : never
